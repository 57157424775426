import React from 'react';

export default function Newsletter(props) {
    return (
        <div id="mc_embed_signup">
            <form
                className="newsletter"
                method="POST"
                action="https://omiami.us1.list-manage.com/subscribe/post?u=b7bd33d0860ecb05667317f15&amp;id=11ee22252d"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                noValidate
                acceptCharset="UTF-8">
                <input
                    className="input input--invert"
                    type="email"
                    name="EMAIL"
                    placeholder="Email address"
                    required
                />
                <div className="flex relative">
                    <input
                        className="input input--invert"
                        type="text"
                        name="FNAME"
                        placeholder="First name"
                        required
                    />
                    <input
                        className="input input--invert"
                        style={{ marginLeft: 8, marginRight: 24 }}
                        type="text"
                        name="LNAME"
                        placeholder="Last name"
                        required
                    />
                    <button
                        id="mc-embedded-subscribe"
                        name="subscribe"
                        type="submit"
                        className="input-button input-button--relative">
                        <span className="sr-only">submit</span>
                    </button>
                </div>

                <div id="mce-responses" className="clear">
                    <p className="" id="mce-error-response" style={{ display: 'none' }} />
                    <p className="" id="mce-success-response" style={{ display: 'none' }} />
                </div>
                <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
                    <input
                        type="text"
                        name="b_b7bd33d0860ecb05667317f15_11ee22252d"
                        tabIndex="-1"
                    />
                </div>
            </form>
            <script
                type="text/javascript"
                src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
            />
            <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';}(jQuery));var $mcj = jQuery.noConflict(true);`,
                }}
            />
        </div>
    );
}
