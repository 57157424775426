import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

export default function LinkButton(props) {
    const { to, copy, classes, invert } = props;

    const btnClasses = classNames({
        button: true,
        invert,
    });

    return (
        <Link to={to} className={`${btnClasses} ${classes}`}>
            <span>{copy}</span>
        </Link>
    );
}
