import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useShop } from '@src/contexts/Shop';
import { formatPrice } from '@src/utils/Shop';

// components
import Button from '@src/components/buttons/Button';
import LineItem from '@src/components/LineItem';

// styles
import './cart.scss';

export default function Cart() {
    const { cart, isOpen, close } = useShop();
    const [hasItems, setHasItems] = useState(false);
    const [price, setPrice] = useState('');

    const handleCheckout = () => {
        window.open(cart.checkoutUrl);
    };

    useEffect(() => {
        setHasItems(cart?.lines?.edges?.length > 0);
    }, [cart]);

    useEffect(() => {
        if (cart?.estimatedCost?.totalAmount) {
            setPrice(
                formatPrice(
                    cart?.estimatedCost?.totalAmount.currencyCode,
                    cart?.estimatedCost?.totalAmount.amount
                )
            );
        }
    }, [cart]);

    return (
        <CSSTransition classNames="modal-transition" in={isOpen} appear unmountOnExit timeout={600}>
            <div className={`cart z-cart ${isOpen ? 'cart--active' : ''}`}>
                <div className="cart__background" />

                <section
                    style={{ height: hasItems ? '' : '100vh' }}
                    className="cart__content relative flex white">
                    <header className="cart__header flex items-center full justify-between mb-32px">
                        <h2 className="h2 m-0 lh-1">Cart</h2>
                        <Button
                            type="button"
                            white
                            copy="X"
                            classes="bold border-1 border-white hover:border-black border-solid"
                            onClick={close}
                        />
                    </header>
                    {hasItems ? (
                        <>
                            <div className="cart__products full">
                                {cart.lines.edges.map(({ node }) => (
                                    <LineItem key={node.id} item={node} />
                                ))}
                            </div>
                            <footer className="cart__footer full">
                                <div className="cart__total uppercase flex full justify-between mb-42px">
                                    <p className="h4">Total</p>
                                    <p className="h4">{price}</p>
                                </div>
                                <div className="flex full justify-center">
                                    <Button
                                        large
                                        white
                                        copy="checkout"
                                        classes="w-full border-1 border-white hover:border-black border-solid"
                                        onClick={handleCheckout}
                                    />
                                </div>
                            </footer>
                        </>
                    ) : (
                        'No Items'
                    )}
                </section>
            </div>
        </CSSTransition>
    );
}
