/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

// content
import { HeaderProvider } from '@src/contexts/Header';

// components
import Banner from '@src/components/banner/Banner';
import Cart from '@src/components/cart/Cart';
import Footer from '@src/components/footer/Footer';
import Header from '@src/components/header/Header';
import { ViewportProvider } from '@src/contexts/Viewport';

// svgs
import WhiteLogo from '@src/svgs/logo--white.svg';
import favicon from '@src/images/favicon.ico';

// lib
import { COLORS } from '@src/lib/colors';
// import NewsletterPopup from '../NewsletterPopup';

const colors = COLORS;

export default function Layout({ location, children }) {
    const data = useStaticQuery(
        graphql`
            {
                globals: craftGlobalSetInterface(handle: { eq: "banner" }) {
                    ... on Craft_banner_GlobalSet {
                        banner {
                            ... on Craft_banner_BlockType {
                                active
                                copy
                                linkUrl
                            }
                        }
                    }
                }
            }
        `
    );
    // layout actions
    const [color, setColor] = useState(0);
    const [menuOn, setMenuOn] = useState(false);

    const [scrollY, setScrollY] = useState(0);
    const [logoOn, setLogoOn] = useState(true);

    const [footerHeight, setFooterHeight] = useState(212);
    const banner = data.globals.banner[0];
    const [firstTime, setFirstTime] = useState(true);
    const randomNum = Math.floor(Math.random() * 50);

    const trackScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        if (
            window.innerHeight + scrollY >
            document.querySelector('.content-wrapper').clientHeight - 200
        ) {
            setLogoOn(false);
        } else {
            setLogoOn(true);
        }
    }, [scrollY]);

    const toggleMenu = () => {
        if (menuOn) {
            setMenuOn(false);
        } else {
            setMenuOn(true);
        }
    };

    const closeMenu = () => {
        if (menuOn) {
            setMenuOn(false);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            document.addEventListener('scroll', trackScroll);

            if (sessionStorage.getItem('firstTime') === null) {
                sessionStorage.setItem('firstTime', false);
            } else {
                setFirstTime(false);
            }

            if (!sessionStorage.getItem('random')) {
                sessionStorage.setItem('random', randomNum);
                setColor(sessionStorage.getItem('random'));
            } else {
                sessionStorage.getItem('random');
                setColor(sessionStorage.getItem('random'));
                document.body.style.backgroundColor =
                    colors[sessionStorage.getItem('random')].background;
            }
        }

        const footer = document.querySelector('.footer');
        const elHeight = footer.offsetHeight;
        setFooterHeight(elHeight);
    }, []);

    return (
        <ViewportProvider>
            <HeaderProvider>
                <Helmet>
                    <link rel="icon" href={favicon} />
                </Helmet>

                {banner.active ? <Banner content={banner} /> : null}

                <Header toggleMenu={toggleMenu} menuOn={menuOn} banner={banner.active} />
                {logoOn ? (
                    <Link to="/" onClick={() => closeMenu()} className="logo__lockup">
                        <WhiteLogo />
                    </Link>
                ) : null}

                <Cart />

                <div
                    style={{ color: colors[color].text }}
                    className="content-wrapper block relative mx-auto overflow-hidden md:overflow-scroll pointer-events-none z-1">
                    <div
                        style={{
                            backgroundColor: colors[color].background,
                            marginBottom: footerHeight,
                        }}
                        className={`content-inner ${
                            colors[color].invertBorder === true ? 'invert-border' : ''
                        }`}>
                        <div
                            className={`${
                                location.pathname !== '/' ? 'view-wrapper' : 'index-wrapper'
                            } z-base`}>
                            <TransitionGroup component={null}>
                                <CSSTransition
                                    key={location.key}
                                    classNames="page-transition"
                                    timeout={500}>
                                    <div>{children}</div>
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    </div>
                </div>
                <Footer closeMenu={closeMenu} />

                {/* <NewsletterPopup /> */}
            </HeaderProvider>
        </ViewportProvider>
    );
}
