import React from 'react';
import Arrow from '@src/svgs/arrow.svg';

// styles
import './banner.scss';

export default function Banner(props) {
    const { content } = props;

    const classes =
        'banner flex justify-center items-center sticky w-full h-banner top-0 px-6 bg-black animate text-center text-white text-base font-bold z-header';

    return (
        <>
            {content.linkUrl.length > 0 ? (
                <a className={classes} href={content.linkUrl}>
                    <span className="mr-6">{content.copy}</span>
                    <span className="flex w-7 h-7 transform rotate-270">
                        <Arrow className="w-full" />
                    </span>
                </a>
            ) : (
                <div className={classes}>{content.copy}</div>
            )}
        </>
    );
}
