import React, { useEffect, useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { debounce } from 'lodash';
import { useShop } from '@src/contexts/Shop';
import { formatPrice } from '@src/utils/Shop';

// components
import Asset from '@src/components/Asset';
import Button from '@src/components/buttons/Button';

export default function LineItem({ item }) {
    const {
        products: { nodes: products },
    } = useStaticQuery(
        graphql`
            {
                products: allCraftShopProductEntry(filter: { sectionHandle: { eq: "shop" } }) {
                    nodes {
                        id: remoteId
                        uri
                        title
                        shopifyProduct
                        multipleImages {
                            url
                            title
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    const productIdParts = item.merchandise.product.id.split('/');
    const productId = productIdParts[productIdParts.length - 1];
    const product = products.find(obj => obj.shopifyProduct[0] === productId);

    const image = product.multipleImages[0];

    const { removeItem, updateItem } = useShop();
    const [quantity, setQuantity] = useState(item.quantity);
    const [price, setPrice] = useState('');

    useEffect(() => {
        setPrice(
            formatPrice(
                item.merchandise.priceV2.currencyCode,
                Number(item.merchandise.priceV2.amount) * quantity
            )
        );
    }, [item, quantity]);

    const handleRemove = () => removeItem(item.id);

    const update = debounce(value => updateItem(item.id, value), 150);

    const debouncedUpdate = useCallback(value => update(value), []);

    const handleQuantityChange = value => {
        if (value !== '' && Number(value) < 1) {
            return;
        }
        setQuantity(value);
        if (Number(value) >= 1) {
            debouncedUpdate(value);
        }
    };

    function doIncrement() {
        handleQuantityChange(Number(quantity || 0) + 1);
    }

    function doDecrement() {
        handleQuantityChange(Number(quantity || 0) - 1);
    }

    return (
        <div className="flex items-center line-item mb-64px">
            <Button
                white
                small
                copy="X"
                classes="border-1 border-white hover:border-black border-solid"
                onClick={handleRemove}
            />

            <div style={{ width: '120px', minWidth: '120px', margin: '0 16px' }}>
                {image ? (
                    <Asset data={image.imageFile} title={image.title} noBorder square />
                ) : null}
            </div>

            <div>
                <h6 className="h6 serif normal-weight paragraph--large mb-6px">{product.title}</h6>
                {item.merchandise.title !== 'Default Title' && (
                    <p className="mb-6px">{item.merchandise.title}</p>
                )}

                <div className="flex flex-wrap items-center mb-8px">
                    <p className="m-0">QTY</p>
                    <div className="flex justify-between items-center" style={{ marginLeft: 8 }}>
                        <button
                            className="paragraph--large w-21px h-21px mx-8px hover:bg-black hover:text-white cursor border-1 border-black border-solid hover:border-white"
                            type="button"
                            style={{ lineHeight: '0.1' }}
                            onClick={doDecrement}>
                            –
                        </button>
                        <div>{quantity}</div>
                        <button
                            className="paragraph--large w-21px h-21px mx-8px hover:bg-black hover:text-white cursor border-1 border-black border-solid hover:border-white"
                            type="button"
                            onClick={doIncrement}>
                            +
                        </button>
                    </div>
                </div>

                <h5>{price}</h5>
            </div>
        </div>
    );
}
