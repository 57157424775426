import React from 'react';
import classNames from 'classnames';

export default function Button({ copy, classes, onClick, type, disabled }) {
    const btnClasses = classNames({
        button: true,
        uppercase: true,
        disabled,
    });

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type || 'button'}
            className={`${btnClasses} ${classes || ''}`}
            tabIndex={0}
            onClick={onClick}>
            <span>{copy}</span>
        </button>
    );
}
