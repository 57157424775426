import { gql } from 'graphql-request';

export const productQuery = gql`
    query product($id: ID!) {
        node(id: $id) {
            ... on Product {
                id
                title
                handle
                availableForSale
                options {
                    id
                    name
                    values
                }
                variants(first: 250) {
                    edges {
                        node {
                            id
                            title
                            priceV2 {
                                amount
                                currencyCode
                            }
                            available: availableForSale
                            selectedOptions {
                                name
                                value
                            }
                        }
                    }
                }
            }
        }
    }
`;

const cartFragment = gql`
    id
    checkoutUrl
    lines(first: 250) {
        edges {
            node {
                id
                quantity
                merchandise {
                    ... on ProductVariant {
                        id
                        title
                        priceV2 {
                            amount
                            currencyCode
                        }
                        available: availableForSale
                        image {
                            id
                            src: originalSrc
                            altText
                            width
                            height
                        }
                        selectedOptions {
                            name
                            value
                        }
                        product {
                            id
                            handle
                        }
                    }
                }
            }
        }
    }
    estimatedCost {
        totalAmount {
            amount
            currencyCode
        }
    }
`;

export const cartQuery = gql`
    query cart($id: ID!) {
        cart(id: $id) {
            ${cartFragment}
        }
    }
`;

export const createCartMutation = gql`
    mutation cartCreateMutation($cartInput: CartInput) {
        cartCreate(input: $cartInput) {
            cart {
                ${cartFragment}
            }
        }
    }
`;

export const addItemMutation = gql`
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
        cartLinesAdd(cartId: $cartId, lines: $lines) {
            cart {
                ${cartFragment}
            }
        }
    }
`;

export const updateItemMutation = gql`
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
            cart {
                ${cartFragment}
            }
        }
    }
`;

export const removeItemMutation = gql`
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
            cart {
                ${cartFragment}
            }
        }
    }
`;
