export const COLORS = [
    {
        background: '#db4d99',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#c30088',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#d70272',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#ec87ac',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#f4b4bf',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#f6607d',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#d90030',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#fc6f6f',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#e4202b',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#fe6844',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#e4b154',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#ff8758',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#faac72',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#e49025',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#f8ba66',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#f4ae00',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#f4cd36',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#f5da00',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#ebe6ac',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#a5b200',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#c8df5d',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#afd566',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#96cf78',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#27c3522',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#86d79e',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#00a965',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#00ae8a',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#00a689',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#00a495',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#007f79',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#6dcfd0',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#00a7bc',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#6fcbd9',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#a8d2d8',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#0078b1',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#009ed1',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#0078c0',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#0078bb',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#599bcb',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#5a80a0',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#5985be',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#bd76b2',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#a30092',
        text: 'white',
        invertBorder: true,
    },
    {
        background: '#ff8da2',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#da8b7d',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#d29c6d',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#c5b2a0',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#dac5b6',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#efcba3',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#bda68d',
        text: 'black',
        invertBorder: false,
    },
    {
        background: '#a38f68',
        text: 'black',
        invertBorder: false,
    },
];

export default COLORS;
