import React, { useState, useEffect } from 'react';
import { useShop } from '@src/contexts/Shop';
import Button from './Button';

export default function CartButton({ copy, classes }) {
    const { cart, toggle } = useShop();
    const [count, setCount] = useState(0);

    useEffect(() => {
        let totalCount = 0;
        if (cart?.lines?.edges?.length > 0) {
            cart?.lines?.edges?.forEach(({ node }) => {
                totalCount += node.quantity;
            });
        }
        setCount(totalCount);
    }, [cart]);

    return (
        <div className="relative">
            {count > 0 ? (
                <Button copy={`${copy} (${count})`} classes={classes} onClick={toggle} />
            ) : null}
        </div>
    );
}
