import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

export default function Asset(props) {
    const { data, title } = props;
    const image = getImage(data);

    const classes = classNames({});

    return image ? (
        <GatsbyImage fit="contain" className={classes} image={image} alt={title} loading="eager" />
    ) : null;
}
