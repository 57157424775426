import React, { createContext, useContext, useState, useEffect } from 'react';
import { isBrowser } from '@src/utils/SSR';
import shopifyClient from '@src/clients/shopify';
import {
    productQuery,
    cartQuery,
    createCartMutation,
    addItemMutation,
    updateItemMutation,
    removeItemMutation,
} from '@src/graphql/shopify';

const defaultValues = {
    loading: false,
    isOpen: false,
    open: () => {},
    close: () => {},
    toggle: () => {},
    queryProduct: () => {},
    addItem: () => {},
    updateItem: () => {},
    removeItem: () => {},
    checkout: {
        lineItems: [],
    },
};

export const ShopContext = createContext(defaultValues);

const cartIdStorageKey = 'shopify_cart_id';

export const ShopProvider = ({ children }) => {
    const [cart, setCart] = useState(defaultValues.cart);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);
    const toggle = () => setIsOpen(!isOpen);

    const setCartItem = newCart => {
        if (isBrowser()) {
            localStorage.setItem(cartIdStorageKey, newCart.id);
        }
        setCart(newCart);
    };

    useEffect(() => {
        const initializeCart = async () => {
            const existingCartId = isBrowser() ? localStorage.getItem(cartIdStorageKey) : null;
            if (existingCartId && existingCartId !== 'null') {
                try {
                    const existingCart = await shopifyClient
                        .request(cartQuery, {
                            id: existingCartId,
                        })
                        .then(({ cart: newCart }) => newCart);
                    if (existingCart) {
                        setCartItem(existingCart);
                        return;
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                    localStorage.setItem(cartIdStorageKey, null);
                }
            }
            const newCart = await shopifyClient
                .request(createCartMutation)
                .then(({ cartCreate }) => cartCreate.cart);
            setCartItem(newCart);
        };
        initializeCart();
    }, []);

    const queryProduct = productId =>
        shopifyClient.request(productQuery, { id: productId }).then(({ node }) => node);

    const addItem = (merchandiseId, quantity) => {
        setLoading(true);
        const cartId = cart.id;
        const lines = [
            {
                merchandiseId,
                quantity: parseInt(quantity, 10),
            },
        ];
        return shopifyClient
            .request(addItemMutation, { cartId, lines })
            .then(({ cartLinesAdd }) => {
                setCart(cartLinesAdd?.cart);
                if (!isOpen) open();
            })
            .finally(() => setLoading(false));
    };

    const updateItem = (id, quantity) => {
        setLoading(true);
        const cartId = cart.id;
        const lines = [
            {
                id,
                quantity: parseInt(quantity, 10),
            },
        ];
        return shopifyClient
            .request(updateItemMutation, { cartId, lines })
            .then(({ cartLinesUpdate }) => {
                setCart(cartLinesUpdate?.cart);
                if (!isOpen) open();
            })
            .finally(() => setLoading(false));
    };

    const removeItem = id => {
        setLoading(true);
        const cartId = cart.id;
        return shopifyClient
            .request(removeItemMutation, { cartId, lineIds: [id] })
            .then(({ cartLinesRemove }) => {
                setCart(cartLinesRemove?.cart);
                if (!isOpen) open();
            })
            .finally(() => setLoading(false));
    };

    return (
        <ShopContext.Provider
            value={{
                ...defaultValues,
                cart,
                queryProduct,
                addItem,
                updateItem,
                removeItem,
                loading,
                isOpen,
                open,
                close,
                toggle,
            }}>
            {children}
        </ShopContext.Provider>
    );
};

export function useShop() {
    return useContext(ShopContext);
}
